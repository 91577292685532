// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: {
    deviceBarChart: [],
    categories: [],
    connectionState: [],
    latestNotification: [],
    deviceMapChart: [],
  },
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      state.datas = { ...state.datas, ...action.payload };
    },
    getConnectionState(state, action) {
      state.datas.connectionState = action.payload.connectionState;
      state.datas.categories = action.payload.categories;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    unMount(state) {
      state.errorMessage = "";
      state.textError = "";
      state.datas = {
        deviceBarChart: [],
        categories: [],
        connectionState: [],
        latestNotification: [],
        deviceMapChart: [],
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll(data) {
  return async (dispatch) => {
    dispatch(slice.actions.getDatas(data));
  };
}
export function getConnectionState() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/dashboard/connection-state/all`);
      dispatch(slice.actions.getConnectionState(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
