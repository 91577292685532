// import { map, filter } from 'lodash';
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";
// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  optionsAutoComplete: [],
  datas: [],
  trail: [],
  event: [],
  notifGeoTest: [],
  isNotif: false,
};

const slice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    startLoading(state) {
      state.textError = "init";
    },

    stopLoading(state) {
      state.textError = "";
    },

    getIsNotif(state, action) {
      state.isNotif = action.payload;
    },
    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
      const data = [];
      action.payload.forEach((values) => {
        data.push({ label: values.nm, id: values.id });
      });
      state.optionsAutoComplete = data;
    },
    getTrail(state, action) {
      state.trail = action.payload;
    },
    getEvent(state, action) {
      state.event = action.payload;
    },
    getNotifGeoEvent(state, action) {
      const init = state.notifGeoTest;

      init.push({
        id: init.length > 0 ? init[init.length - 1].id + 1 : 1,
        ...action.payload,
      });

      state.notifGeoTest = init;
    },

    removeByIndexNotifGeoEvent(state, action) {
      const init = state.notifGeoTest;
      const index = init.findIndex((value) => value.id === action.payload);
      init.splice(index, 1);
      state.notifGeoTest = init;
    },
    removeAllNotifGeoEvent(state) {
      state.notifGeoTest = [];
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    unMount(state) {
      state.errorMessage = "";
      state.textError = "";
      state.optionsAutoComplete = [];
      state.datas = [];
      state.trail = [];
      state.event = [];
      state.notifGeoTest = [];
      state.isNotif = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getAll(location) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/unit/location`, {
        params: {
          location,
        },
      });
      // console.log(response.data);
      dispatch(slice.actions.getDatas(response.data.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unitTrail(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/unit/trail`, {
        // location: data.location,
        timeFrom: data.dateFrom,
        timeTo: data.dateTo,
        itemId: data.unit,
      });
      dispatch(slice.actions.getTrail(response.data.trail));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getEvent(event) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(`/unit/event`);
      dispatch(slice.actions.getEvent(event));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getNotifGeoFence(event) {
  return async (dispatch) => {
    dispatch(slice.actions.getNotifGeoEvent(event));
  };
}

export function removeByIndexNotifGeoFence(index) {
  return async (dispatch) => {
    dispatch(slice.actions.removeByIndexNotifGeoEvent(index));
  };
}

export function removeAllNotifGeoFence() {
  return async (dispatch) => {
    dispatch(slice.actions.removeAllNotifGeoEvent());
  };
}

export function getIsNotif(isNotif) {
  return async (dispatch) => {
    dispatch(slice.actions.getIsNotif(isNotif));
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
// export function postOne(data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post(`/user`, {
//         name: data.name,
//         username: data.username,
//         password: data.password,
//         roleName: data.roleName,
//       });
//       const response = await axios.get(`/user`);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-add"));
//     } catch (error) {
//       // if (error) {
//       //   dispatch(slice.actions.hasError(error));
//       //   // console.log(error);
//       // } else {
//       dispatch(slice.actions.hasError({ error: "400", message: error }));
//       // }

//       // console.log(error.toJSON());
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

// export function putOne(data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.put(`/user/${data.id}`, {
//         name: data.name,
//         username: data.username,
//         password: data.password,
//         roleName: data.roleName,
//       });
//       const response = await axios.get(`/user`);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-edit"));
//     } catch (error) {
//       // if (error) {
//       //   dispatch(slice.actions.hasError(error));
//       //   // console.log(error);
//       // } else {
//       dispatch(slice.actions.hasError({ error: "400", message: error }));
//       // }

//       // console.log(error.toJSON());
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

// export function deleteOne(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.delete(`/user/${id}`);
//       const response = await axios.get(`/user`);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-edit"));
//     } catch (error) {
//       dispatch(slice.actions.hasError({ error: "400", message: error }));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }
