// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  textError: "",
  datas: [],
  location: [],
};

const slice = createSlice({
  name: "prosecutor",
  initialState,
  reducers: {
    // LOADING
    startLoading(state, action) {
      state.textError = "loadingInit";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    getDatas(state, action) {
      state.textError = "200";
      state.datas = action.payload;
    },
    getLocation(state, action) {
      state.location = action.payload;
    },

    // ERROR
    hasError(state, action) {
      state.textError = action.payload;
    },
    // unMount
    unMount(state) {
      state.textError = "";
      state.datas = [];
      state.location = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/prosecutor`);
      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByKejari(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/prosecutor/kejari/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/prosecutor/user/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function postData(formData, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // Promise.all()
      await axios.post(`/prosecutor`, formData);
      const response = await axios.get(`/prosecutor/user/${userId}`);
      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-add"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function editOne(id, formData, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/prosecutor/${id}`, formData);
      const response = await axios.get(`/prosecutor/user/${userId}`);

      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function deleteOne(id, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/prosecutor/${id}`);
      const response = await axios.get(`/prosecutor/user/${userId}`);

      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-delete"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/location/${id}`);

      dispatch(slice.actions.getLocation(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
// ----------------------------------------------------------------------
