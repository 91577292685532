import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { Stack } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import Scrollbar from "../../Scrollbar";
// ----------------------------------------------------------------------

const hideScrollbar = {
  // msOverflowStyle: "none",
  // scrollbarWidth: "none",
  // overflowY: "scroll",
  // "&::-webkit-scrollbar": {
  //   display: "none",
  // },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        bgcolor: "background.neutral",
        borderRadius: 1,
        px: 0.5,
      }}
    >
      <Scrollbar>
        <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
          {navConfig.map((group, i) => (
            <Stack key={`${i}`} direction="row" flexShrink={0}>
              {group.items.map((list, index) => (
                <div key={index}>
                  {list.disable && <NavListRoot list={list} />}
                </div>
              ))}
            </Stack>
          ))}
        </Stack>
      </Scrollbar>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
