import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { Box, useMediaQuery } from "@mui/material";
// hooks
// import useSettings from "../../hooks/useSettings";
// import useResponsive from "../../hooks/useResponsive";
// import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// config
import { HEADER } from "../../config";
//
import DashboardHeader from "./header";

import NavbarVertical from "./navbar/NavbarVertical";
// import NavbarHorizontal from "./navbar/NavbarHorizontal";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  // const isDesktop = useResponsive("up", "lg");
  const isDesktop = useMediaQuery("(max-width:1200px)", { noSsr: true });

  // console.log(isDesktop);
  const [open, setOpen] = useState(false);
  const verti = true;
  return (
    <>
      <DashboardHeader
        onOpenSidebar={() => setOpen(true)}
        verticalLayout={verti}
      />
      {isDesktop && (
        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}

      {/* {isDesktop ? <NavbarHorizontal /> : <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />} */}

      <Box
        component="main"
        sx={{
          // px: { lg: 2 },
          pt: {
            xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
            lg: `${20}px`,
          },
          pb: {
            xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
            lg: `${20}px`,
          },
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
