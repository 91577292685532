// import { map, filter } from 'lodash';
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";
// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: [],
  location: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
    },
    getLocation(state, action) {
      state.location = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    unMount(state) {
      state.errorMessage = "";
      state.textError = "";
      state.datas = [];
      state.location = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/location/${id}`);
      dispatch(slice.actions.getLocation(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

// export function getLocation(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/user/location/${id}`);
//       dispatch(slice.actions.getDatas(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

export function postOne(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/user`, formData);
      const response = await axios.get(`/user`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-add"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function putOne(formData, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/user/${id}`, formData);
      const response = await axios.get(`/user`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteOne(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/user/${id}`);
      const response = await axios.get(`/user`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
