import { memo } from "react";
// @mui
import { Container } from "@mui/material";

// config
// import { HEADER } from "../../../config";
// components
import { NavSectionHorizontal } from "../../../components/nav-section";
//
import NavConfig from "../../NavConfig";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  return (
    // <RootStyle>
    <Container maxWidth={false}>
      {/* <SimpleBarStyle timeout={500} clickOnTrack={false}> */}
      <NavSectionHorizontal navConfig={NavConfig()} />
      {/* </SimpleBarStyle> */}
    </Container>
    // </RootStyle>
  );
}

export default memo(NavbarHorizontal);
