// components
import DashboardIcon from "@mui/icons-material/Dashboard";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import RadarIcon from "@mui/icons-material/Radar";
import HistoryIcon from "@mui/icons-material/History";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SettingsIcon from "@mui/icons-material/Settings";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import MapIcon from "@mui/icons-material/Map";
import GavelIcon from "@mui/icons-material/Gavel";
import useAuth from "../hooks/useAuth";
// ----------------------------------------------------------------------
const SidebarConfig = () => {
  const { role } = useAuth();
  // console.log(role);
  return [
    // GENERAL
    // ----------------------------------------------------------------------

    {
      // subheader: 'general v3.0.0',
      items: [
        {
          title: "Dashboard",
          path: "/dashboard",
          icon: <DashboardIcon />,
          disable: role.detectionKitManager,
        },
        {
          title: "Kit Monitoring",
          path: "/detection-kit-manager",
          icon: <RadarIcon />,
          disable: role.detectionKitManager,
        },
        {
          title: "Kit Assignment",
          path: "/kit-assignment",
          icon: <CompareArrowsIcon />,
          disable: role.kitAssignment,
        },

        {
          title: "Kit Tracking",
          path: "/history-track",
          icon: <HistoryIcon />,
          disable: role.historyTrack,
        },
        {
          title: "Geofence",
          path: "/geofence",
          icon: <MapIcon />,
          disable: role.geofence,
        },
        {
          title: "Device Management",
          path: "/device-manager",
          icon: <DevicesOtherIcon />,
          disable: role.deviceManager,
        },
        {
          title: "Prisoner Management",
          path: "/prisoner-manager",
          icon: <AccountBoxIcon />,
          disable: role.prisonerManager,
        },

        {
          title: "Prosecutor Management",
          path: "/prosecutor-manager",
          icon: <GavelIcon />,
          disable: role.prosecutorManager,
        },
        // {
        //   title: "Master Kit",
        //   path: "/master-kit",
        //   icon: <AccountBoxIcon />,
        //   disable: role.prisonerManager,
        // },
        {
          title: "settings",
          // path: '/dashboard/user',
          icon: <SettingsIcon />,
          disable:
            role.userManagement ||
            role.userRole ||
            role.globalSettings ||
            role.licenseManager ||
            role.log,
          children: [
            {
              title: "User Management",
              path: "/user-management",
              disable: role.userManagement,
            },
            {
              title: "Role Management",
              path: "/user-role",
              disable: role.userRole,
            },

            {
              title: "Attorney Management",
              path: "/jaksa-managament",
              disable: role.attorneyManagement,
            },
            {
              title: "License Management",
              path: "/license-manager",
              disable: role.licenseManager,
            },
            {
              title: "Global Settings",
              path: "/global-setting",
              disable: role.globalSettings,
            },
            { title: "Logs", path: "/log", disable: role.log },
          ],
        },
      ],
    },
  ];
};

export default SidebarConfig;
