// import { map, filter } from 'lodash';
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";
// ----------------------------------------------------------------------

const initialState = {
  token: "",
  isAuth: false,
  errorMessage: "",
  textError: "",
  loadingValidate: "false",
};

const slice = createSlice({
  name: "whatsapp",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
      if (action.payload.error === "200-wa-validate") {
        state.loadingValidate = "200";
      }
    },
    //
    getIsAuth(state, action) {
      state.isAuth = action.payload;
    },
    getToken(state, action) {
      state.token = action.payload;
    },
    getLoadingValidate(state, action) {
      state.loadingValidate = action.payload;
    },
    unMount(state) {
      state.token = "";
      state.isAuth = false;
      state.errorMessage = "";
      state.textError = "";
      state.loadingValidate = "false";
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------
export function getIsAuth(isAuth) {
  return async (dispatch) => {
    dispatch(slice.actions.getIsAuth(isAuth));
  };
}

export function getToken(token) {
  return async (dispatch) => {
    dispatch(slice.actions.getToken(token));
  };
}

export function getQrWhatsapp() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/whatsapp/qr`);
      dispatch(slice.actions.getToken(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
  };
}

export function validateWaNumber(phone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/whatsapp/validate`, {
        phone,
      });
      dispatch(
        slice.actions.hasError({
          error: "200-wa-validate",
          message: "registered",
        })
      );
    } catch (error) {
      dispatch(
        slice.actions.hasError({ error: "400-wa-validate", message: error })
      );
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getLoadingValidate(loadingTxt) {
  return async (dispatch) => {
    dispatch(slice.actions.getLoadingValidate(loadingTxt));
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
