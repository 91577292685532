import { memo } from "react";
// @mui
import { Container } from "@mui/material";
// config
// components
import { NavSectionHorizontal } from "../../../components/nav-section";
//
import NavConfig from "../../NavConfig";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  return (
    // <RootStyle>
    <Container maxWidth={false}>
      <NavSectionHorizontal navConfig={NavConfig()} />
    </Container>
    // </RootStyle>
  );
}

export default memo(NavbarHorizontal);
