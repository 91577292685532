// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "init",
  datas: [],
  location: {
    lat: "",
    lng: "",
    mcc: "",
    mnc: "",
    lac: "",
    cellId: "",
  },
};

const slice = createSlice({
  name: "pod",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "loading";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
    },
    getLocation(state, action) {
      // state.textError = "200";
      state.location = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    unMount(state) {
      state.errorMessage = "";
      state.textError = "init";
      state.datas = [];
      state.location = {
        lat: "",
        lng: "",
        mcc: "",
        mnc: "",
        lac: "",
        cellId: "",
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------
export function getAllMsisdn() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/pod/msisdn`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByUserId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/pod/user/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getLocationPod(iccid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/pod/location/${iccid}`);
      dispatch(slice.actions.getLocation(response.data));
      if (response.data.lat && response.data.lng) {
        dispatch(slice.actions.getTextError("200-location"));
      } else {
        dispatch(slice.actions.getTextError("200-location-empty"));
      }
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
