import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
// import { PATH_PAGE } from 'src/routes/paths';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  roleName: PropTypes.string, // Example ['admin', 'leader']
  children: PropTypes.node,
};

export default function RoleBasedGuard({ roleName, children }) {
  const { role, isAuthenticated } = useAuth();
  // console.log(role[roleName]);
  if (!isAuthenticated) {
    return <Navigate to="/auth" replace />;
  }

  if (!role[roleName]) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
