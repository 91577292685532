// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: [],
  id: "",
  noTlpAdmin: "",
  qrWhatsapp: "",
};

const slice = createSlice({
  name: "globalSetting",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.id = action.payload[0].id;
      state.noTlpAdmin = action.payload[0].tlpAdmin;
    },
    getQr(state, action) {
      state.qrWhatsapp = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    unMount(state) {
      state.errorMessage = "";
      state.textError = "";
      state.datas = [];
      state.id = "";
      state.noTlpAdmin = "";
      state.qrWhatsapp = "";
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/global-setting`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      dispatch(
        slice.actions.hasError({ error: "400", message: "Request Failed" })
      );
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getQrWhatsapp() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/whatsapp/qr`);
      dispatch(slice.actions.getQr(response.data));
    } catch (error) {
      dispatch(
        slice.actions.hasError({ error: "400", message: "Request Failed" })
      );
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function putOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/global-setting/${data.id}`, {
        tlpAdmin: data.noTlpAdmin,
      });
      const response = await axios.get(`/global-setting`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: "400", message: error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function validateWaNumber(phone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/whatsapp/validate`, {
        phone,
      });
      dispatch(
        slice.actions.hasError({ error: "200-wa", message: "registered" })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ error: "400-wa", message: error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
// ----------------------------------------------------------------------
