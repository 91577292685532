// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: [],
};

const slice = createSlice({
  name: "notif",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    unMount(state) {
      state.errorMessage = "";
      state.textError = "";
      state.datas = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------
export function getAllByLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/notif/user/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
